var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"mb-6"},[_c('div',{staticClass:"heading d-flex align-center mb-3"},[_c('h3',{staticClass:"title"},[_vm._v(" Search & manage cases ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"rounded-0 text-caption mr-2",attrs:{"solo":"","dense":"","hide-details":"","flat":"","placeholder":"Search by name or steamid","background-color":"grey800"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"unwidth unheight align-self-stretch",attrs:{"color":"grey300","tile":"","small":"","ripple":false,"loading":_vm.loading.list},on:{"click":_vm.fetchApiData}},[_c('fai',{staticClass:"grey100--text",attrs:{"icon":['fas','sync']}})],1)],1),_c('v-data-table',{staticClass:"rounded-0 data-table",attrs:{"item-key":"username","headers":_vm.headers,"items":_vm.entries,"options":_vm.options,"footer-props":{ itemsPerPageOptions: [5, 10, 25] },"server-items-length":_vm.total,"loading":_vm.loading.list},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('h4',{staticClass:"text-body-2 grey100--text lh-1-2"},[(item.data && item.data.type === 'daily-case')?_c('fai',{staticClass:"blue400--text mr-1",attrs:{"icon":['fad', 'gift-card']}}):_vm._e(),(item.data && item.data.type === 'level-case')?_c('fai',{staticClass:"blue400--text mr-1",attrs:{"icon":['fad', 'crown']}}):_vm._e(),_vm._v(" "+_vm._s(value)+" "),(item.data && item.data.disabled)?_c('fai',{staticClass:"primary600--text ml-1",attrs:{"icon":['far', 'times']}}):_vm._e()],1),_c('h5',{staticClass:"text-caption lh-1-2 text--disabled",domProps:{"textContent":_vm._s(item.slug)}})]}},{key:"item.price",fn:function(ref){
var value = ref.value;
return [(!value)?_c('span',{staticClass:"primary500--text"},[_vm._v("Free")]):_c('h4',{staticClass:"text-body-2 green400--text"},[_vm._v(" $"+_vm._s(_vm._f("toCurrency")(value / 100))+" ")])]}},{key:"item.data.openedCount",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(!value)?_c('span',{staticClass:"text--disabled"},[_vm._v("—")]):_c('div',[_c('h4',{staticClass:"text-body-2 green200--text"},[_vm._v(" "+_vm._s(_vm._f("toLocale")(value))+" ")])])]}},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("relativeTime")(new Date(value).getTime(),'twitter')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toDateFormat")(new Date(value).getTime(),'YYYY-MM-DD HH:mm:ss')))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('fai',{staticClass:"primary500--text link",attrs:{"icon":['fad', 'pencil']},on:{"click":function($event){return _vm.onEdit(item)}}})]}}])})],1),_c('section',{staticClass:"pa-4 grey800"},[_c('AdminCaseForm',{attrs:{"value":_vm.form.case,"pricelist":_vm.pricelist},on:{"submit":_vm.onFormSubmit,"cancel":_vm.onFormCancel}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }