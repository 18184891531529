<template>
  <div>
    <!-- user searching  -->
    <section class="mb-6">
      <div class="heading d-flex align-center mb-3">
        <h3 class="title">
          Search &amp; manage cases
        </h3>
        <v-spacer />
        <v-text-field
          v-model="search"
          solo dense hide-details flat
          class="rounded-0 text-caption mr-2"
          placeholder="Search by name or steamid"
          background-color="grey800"
        />
        <v-btn
          color="grey300"
          tile small class="unwidth unheight align-self-stretch"
          :ripple="false" :loading="loading.list"
          @click="fetchApiData"
        >
          <fai :icon="['fas','sync']" class="grey100--text" />
        </v-btn>
      </div>

      <v-data-table
        class="rounded-0 data-table"
        item-key="username"
        :headers="headers"
        :items="entries"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: [5, 10, 25] }"
        :server-items-length="total"
        :loading="loading.list"
      >
        <template #item.name="{ item, value }">
          <h4 class="text-body-2 grey100--text lh-1-2">
            <fai v-if="item.data && item.data.type === 'daily-case'" class="blue400--text mr-1" :icon="['fad', 'gift-card']" />
            <fai v-if="item.data && item.data.type === 'level-case'" class="blue400--text mr-1" :icon="['fad', 'crown']" />
            {{ value }}
            <fai v-if="item.data && item.data.disabled" class="primary600--text ml-1" :icon="['far', 'times']" />
          </h4>
          <h5 class="text-caption lh-1-2 text--disabled" v-text="item.slug" />
        </template>

        <template #item.price="{ value }">
          <span v-if="!value" class="primary500--text">Free</span>
          <h4 v-else class="text-body-2 green400--text">
            ${{ value / 100 | toCurrency }}
          </h4>
        </template>

        <template #item.data.openedCount="{ value, item }">
          <span v-if="!value" class="text--disabled">&mdash;</span>
          <div v-else>
            <h4 class="text-body-2 green200--text">
              {{ value | toLocale }}
            </h4>
          </div>
        </template>

        <template #item.createdAt="{ value }">
          <v-tooltip top color="primary500">
            <template #activator="{on}">
              <span v-on="on">{{ new Date(value).getTime() | relativeTime('twitter') }}</span>
            </template>
            <span>{{ new Date(value).getTime() | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
          </v-tooltip>
        </template>

        <template #item.actions="{ item }">
          <fai
            :icon="['fad', 'pencil']"
            class="primary500--text link"
            @click="onEdit(item)"
          />
        </template>
      </v-data-table>
    </section>

    <!-- create bot -->
    <section class="pa-4 grey800">
      <AdminCaseForm
        :value="form.case"
        :pricelist="pricelist"
        @submit="onFormSubmit"
        @cancel="onFormCancel"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { debounce } from '@/utils'
import AdminCaseForm from './components/AdminCaseForm'

const newCaseFormat = () => ({
  creator: null,
  name: null,
  slug: null,
  price: null,
  contents: [],
  options: {},
})

export default {
  components: { AdminCaseForm },
  data() {
    return {
      loading: {
        list: false,
        create: false,
      },
      total: 0,
      search: '',
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 10,
        mustSort: true,
      },
      headers: [
        { text: 'Case', value: 'name', align: 'start' },
        {
          text: 'Price', value: 'price', align: 'start',
        },
        {
          text: 'Amount opened', value: 'data.openedCount', align: 'end',
        },
        {
          text: 'Created', value: 'createdAt', align: 'end',
        },
        {
          text: null, value: 'actions', sortable: false, align: 'end',
        },
      ],
      entries: [],
      form: {
        case: newCaseFormat(),
      },
      pricelist: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
    search: {
      handler(val) {
        this.onSearchInput(val)
      },
      deep: true,
    },
  },
  created() {
    this.getPricelist()
  },
  methods: {
    onSearchInput: debounce(function fn(val) {
      this.fetchApiData()
    }, 500),
    onEdit(item) {
      this.form.case = {
        id: item.id,
        creator: item.creatorId,
        name: item.name,
        slug: item.slug,
        price: item.price,
        contents: item.contents.content,
        options: item.data ?? {},
      }
    },
    async fetchApiData() {
      this.loading.list = true

      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        const { entries, total } = await this.$socket.request('admin.cases.get', {
          page,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
          search: this.search,
        })

        this.entries = entries
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading.list = false
      }
    },
    async getPricelist() {
      try {
        this.pricelist = await this.$socket.request('admin.cases.pricelist')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async onFormSubmit(form) {
      this.loading.create = true

      try {
        await this.$socket.request('admin.cases.createOrUpdate', form)
        this.form.case = newCaseFormat()

        this.$toast.success(`Case ${form.id ? 'updated' : 'created'} successfully.`)
        this.fetchApiData()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.create = false
      }
    },
    onFormCancel() {
      this.form.case = newCaseFormat()
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.user-obj {
  overflow: auto;
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    border-radius: 0;
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
