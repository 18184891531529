<template>
  <v-row class="pt-4">
    <v-col cols="12" md="12" class="d-flex align-center">
      <v-img
        :src="caseImage"
        :aspect-ratio="1"
        width="64"
        contain
        class="mr-3 flex-grow-0"
      />
      <div>
        <h4 v-if="value.id">
          Editing case {{ form.name }}
        </h4>
        <h4 v-else>
          Creating case {{ form.name }}
        </h4>
        <p class="text-body-2 blue200--text mb-2">
          ({{ value.id || 'Not yet created' }})
        </p>
      </div>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="form.name"
        type="text"
        solo dense flat
        placeholder="Case name"
        hide-details
        background-color="grey600"
      />
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model.trim="form.slug"
        type="text"
        solo dense flat
        placeholder="Url slug (no spaces)"
        hide-details
        background-color="grey600"
      />
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="form.options.imageUrl"
        type="text"
        solo dense flat
        placeholder="Case image url"
        hide-details
        background-color="grey600"
      />
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model.trim="form.creator"
        type="text"
        solo dense flat
        placeholder="Creator steamid (empty=you)"
        hide-details
        background-color="grey600"
      />
    </v-col>
    <v-col cols="12" md="4">
      <v-currency-field
        v-model="form.price"
        :options="{ allowNegative: false }"
        placeholder="Case price"
        class="rounded-0 grey100--text"
        solo hide-details dense
        flat background-color="grey600"
      />
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model.trim="form.options.type"
        type="text"
        solo dense flat
        placeholder="Type (daily-case, level-case, etc)"
        hide-details
        background-color="grey600"
      />
    </v-col>

    <!-- items -->
    <v-col cols="12" md="12">
      <h4 class="mb-2">
        Case contents
      </h4>
      <div v-if="caseContents.length" class="case-items grey600 pa-3 mb-4 d-flex">
        <div v-for="item in caseContents" :key="item.id" class="item grey800 pa-3 mr-2">
          <v-img
            :src="`https://community.cloudflare.steamstatic.com/economy/image/${item.image}/164x164`"
            aspect-ratio="1" width="56" contain
          />

          <p class="text-body-2" :style="{color: item.color}" v-text="item.id" />
          <div class="blue400--text text-body-2">
            ${{ item.price | toScrap }}
          </div>
          <div class="blue200--text text-caption">
            <span>{{ item.weight | toLocale }}</span>
            <span class="mx-2">&ndash;</span>
            <span>{{ item.weight / totalCaseWeight * 100 | toLocale(4) }}%</span>
          </div>
        </div>
      </div>

      <div class="mb-6">
        <v-expansion-panels accordion flat tile>
          <v-expansion-panel>
            <v-expansion-panel-header color="grey700">
              Case builder
            </v-expansion-panel-header>
            <v-expansion-panel-content color="grey700">
              <AdminCaseBuilder :items="items" :contents="form.contents" @insert="form.contents = $event" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-col>


    <v-col cols="6">
      <v-switch
        v-model="form.options.disabled"
        class="mt-0" color="primary500"
        label="Disabled"
        dense hide-details inset
      />
    </v-col>

    <v-col cols="6">
      <v-combobox
        v-model="form.options.tags"
        :items="[]"
        solo flat small-chips multiple
        clearable
        class="rounded-0"
        background-color="grey600"
        placeholder="Add tags to this case"
        label="Add tags to this case"
      />
    </v-col>


    <v-col cols="12" class="d-flex justify-end">
      <v-btn
        class="rounded-0 font-weight-bold mr-3"
        color="grey400 text--secondary"
        :ripple="false"
        @click="$emit('cancel')"
      >
        Reset
      </v-btn>

      <v-btn
        class="rounded-0 font-weight-bold"
        color="blue700 blue300--text"
        :ripple="false"
        :loading="loading"
        @click="onSubmit"
      >
        <fai :icon="['fas', 'check']" class="mr-2" />
        {{ value.id ? 'Update' : 'Create' }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import sortBy from 'lodash.sortby'
import { mapGetters } from 'vuex'

export default {
  components: {
    AdminCaseBuilder: () => import('./AdminCaseBuilder'),
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
    pricelist: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: { ...this.value },
    }
  },
  computed: {
    ...mapGetters({
      itemdb: 'items/items',
    }),
    items() {
      const { itemdb, pricelist } = this

      return sortBy(
        Object.entries(pricelist).map(([name, price]) => ({ name, price, ...itemdb[name] })),
        'price',
      ).reverse()
    },
    caseContents() {
      const { itemdb } = this

      return this.form.contents.map(item => ({
        ...item,
        ...itemdb[item.id],
      }))
    },
    totalCaseWeight() {
      return this.form.contents.reduce((acc, v) => acc + v.weight, 0)
    },
    caseImage() {
      return this.form?.options?.imageUrl
    },
  },
  watch: {
    value(val) {
      if (JSON.stringify(val) === JSON.stringify(this.form)) return

      this.form = { ...val }
    },
    'form.name': function (val) {
      if (val && !this.form.id) {
        this.form.slug = val.toLowerCase().replaceAll(' ', '-').replace(/[^\w-]+/g, '')
      }
    },
    form: {
      handler(val) {
        this.$emit('input', { ...val })
      },
      deep: true,
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form)
    },
  },
}
</script>

<style lang="scss">
.case-items {
  overflow-x: auto;

  .item {
    min-width: 90px;
  }
}
</style>
